const GlobalEnums = {

    Entities: {
        About: 57,
        Campaign: 58,
        Cart: 59,
        Checkout: 60,
        Index_Contact_Us: 61,
        FaqPage: 62,
        Index_Home_Page: 63,
        Login: 64,
        ResetPassword: 65,
        AllProducts: 66,
        ProductDetail: 67,
        OrdersHistory: 68,
        Signup: 69,
        UpdateProfile: 70,
        CardSection: 71,
        CheckoutStripForm: 72,
        OrderSummary: 73,
        BannerSlider: 74,
        Footer: 75,
        MegaMenu: 76,
        Navbar: 77,
        TopHeader: 78,
        LoginUserModal: 79,
        Wishlist: 80,
        NewProducts: 81,
        PopularProducts: 82,
        ProductsFilterOptions: 83,
        ProductsGridTypeOne: 84,
        ProductVariants: 85,
        RelatedProducts: 86,
        BestFacilities: 87,
        CompaignSection: 88,
        PopularCategories: 89,
        SiteLeftSidebarFilter: 90,
        SizeGuide: 91,
        SubscribeNewsLetter: 92,
        BecomeVendor : 94,
        WebsiteSearchHeader: 97,
        ProductBox_1: 98
    },

}
export default GlobalEnums;

